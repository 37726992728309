import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import BackgroundImage from '../components/BackgroundImage';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import ErrorMessageView from '../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import useFetchDocument from '../hooks/useFetchDocument';
import HTMLFlipBook from 'react-pageflip';
import useFetchMedia from '../hooks/useFetchMedia';
import Image from '../components/Image';
import React from 'react';
import { getImageDimensions } from '../common/Media';
import { MEDIA_PREFIX } from '../Settings';
import PrevIconSvg from "../assets/icon_prev_white.svg";
import NextIconSvg from "../assets/icon_next_white.svg";

const BookWrapper = styled.div.attrs((p: any) => ({
  visible: p.visible || false
}))`
  margin-top: ${p => p.theme.scaleFactor * 140}px;
  margin-bottom: ${p => p.theme.scaleFactor * 50}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1s;
  opacity: ${p => p.visible ? `1` : `0`};
`

const Book = styled.div.attrs((p: any) => ({
  visible: p.visible || false,
  offsetX: p.offsetX || 0
}))`
  position: absolute;
  z-index: 1;
  transition: all .5s;
  transform: translateX(${p => p.offsetX}px);

  & figure {
    background-color: transparent;
  }
`;

const PageButton = styled.button.attrs((p: any) => ({
  active: p.active || false,
}))`
  position: absolute;
  top: 50%;
  width: 3vw;
  height: 3vw;
  cursor: pointer;
  z-index: 0;
  border-radius: 50%;
  border: 0;
  background-color: rgba(0, 0, 0, .3);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform: translateY(-50%);
  opacity: ${p => p.active ? "1" : ".5"};

  &::before {
    content: "";
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &.prev {
    background-image: url(${PrevIconSvg});
    background-size: 1.5vw;
    left: 4vw;
  }

  &.next {
    background-image: url(${NextIconSvg});
    background-size: 1.5vw;
    right: 4vw;
  }
`;

const PageImg = styled(Image)``;

interface FlipBookProps {
  previewData?: any,
  overrideTitle?: string
}

interface PageProps {
  media: any,
  bounds: any,
  index: number,
  setPageSize: any
}

/**
 * Render a flipbook view
 * @returns {JSX.Element} Component template
 */
const FlipBookView: FC<FlipBookProps> = ({previewData=null, overrideTitle}) => {
  const bookRef = useRef<any>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage } = useContext(AppContext);
  const [pageList, setPageList] = useState<any>([]);
  const [bookBounds, setBookBounds] = useState<any>({});
  const [pageSize, setPageSize] = useState<any>({ width: 1, height: 1 });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [offsetX, setOffsetX] = useState<number>(0);
  const [data, error] = useFetchDocument(documentId, previewData);

  // Collect all media ids from media list and article blocks
  useEffect(() => {
    let isSubscribed = true;
    if (!data) { return; }

    (async() => {
      let mediaList: any[] = [];
      data?.content?.pages?.forEach((mediaItem) => {
        mediaList.push(mediaItem);
      });

      // Save to mediaQueue when all media ids are collected
      isSubscribed && setPageList(mediaList);
    })();

    return () => { isSubscribed = false; }
  }, [data, currentLanguage]);

  // Update bounds
  const updateBounds = useCallback(() => {
    let bookWidth = (window.innerWidth / 2) - (window.innerWidth * .1);
    const bookHeight = window.innerHeight - 250;

    if (data?.content?.settings?.pageLayout === "single") {
      bookWidth = window.innerWidth - (window.innerWidth * .2);
    }

    // Get proportions
    const widthRatio = bookWidth / pageSize.width;
    const heightRatio = bookHeight / pageSize.height;
    const scaleFactor = Math.min(widthRatio, heightRatio);

    setBookBounds({
      width: pageSize.width * scaleFactor,
      height: pageSize.height * scaleFactor,
      minWidth: pageSize.width * scaleFactor,
      maxWidth: pageSize.width * scaleFactor,
      minHeight: pageSize.height * scaleFactor,
      maxHeight: pageSize.height * scaleFactor
    });
  }, [pageSize, data]);

  // Update bounds when window resizes or when page dimensions are received
  useEffect(() => {
    updateBounds();
    window.addEventListener('resize', () => updateBounds());
  }, [updateBounds, pageSize]);

  // Make sure the first and last page is displayed in center position
  const checkOffset = useCallback((pageNumber) => {
    if (data?.content?.settings?.pageLayout === "book") {
      if (pageNumber === 0) {
        setOffsetX(-bookBounds.width/2);
      } else if (pageNumber >= pageCount -1) {
        setOffsetX(bookBounds.width/2);
      } else {
        setOffsetX(0);
      }
    } else {
      setOffsetX(0);
    }
  }, [bookBounds, pageCount, data]);

  // On initiation
  const onInit = useCallback(() => {
    if (bookRef && bookRef.current) {
      const pageCount = bookRef.current.pageFlip()?.getPageCount() || 0;
      const currentPage = bookRef.current.pageFlip()?.getCurrentPageIndex() || 0
      setPageCount(pageCount);
      checkOffset(currentPage);
    }
  }, [checkOffset]);

  // Turn to previous page
  const openPreviousPage = useCallback(() => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipPrev();
    }
  }, []);

  // Turn to next page
  const openNextPage = useCallback(() => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipNext();
    }
  }, []);

  // Activate when a page is flipped
  const onFlip = useCallback((e: any) => {
    setCurrentPage(e.data);
    checkOffset(e.data);
  }, [checkOffset]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) || (data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
        <BackgroundImage media={data?.content?.background?.image} backgroundColor={data?.content?.background?.backgroundColor} />
        <BookWrapper ref={wrapperRef} visible={Boolean(pageSize?.height > 1)}>
          <Book offsetX={offsetX}>
            <HTMLFlipBook key={`flipbook-${window.innerWidth}-${bookBounds?.height}x${bookBounds?.width}-${data?.content?.settings?.pageLayout}`} ref={bookRef} width={bookBounds.width} height={bookBounds.height} className={''} style={{}} startPage={(data?.content?.settings?.startPage || 1) - 1} size={'fixed'} minWidth={bookBounds.minWidth} maxWidth={bookBounds.maxWidth} minHeight={bookBounds.minHeight} maxHeight={bookBounds.maxHeight} drawShadow={true} flippingTime={800} usePortrait={data?.content?.settings?.pageLayout === "single" ? true : false} startZIndex={1} autoSize={true} maxShadowOpacity={.3} showCover={true} mobileScrollSupport={true} clickEventForward={false} useMouseEvents={true} swipeDistance={30} showPageCorners={true} disableFlipByClick={false} onInit={onInit} onFlip={onFlip}>
              {Object.values(pageList)?.map((item: any, i: number) => {
                return <PageView key={`page${i}`} media={item} bounds={bookBounds} index={i} setPageSize={setPageSize}/>
              })}
            </HTMLFlipBook>
          </Book>
          {data?.content?.settings?.displayArrows && (
            <>
              <PageButton className="prev" onClick={openPreviousPage} active={Boolean(currentPage > 0)}/>
              <PageButton className="next" onClick={openNextPage} active={Boolean(currentPage < pageCount - 1)}/>
            </>
          )}
        </BookWrapper>
      </PageTransition>
    </>
  );
}

/**
 * Render a single page
 * @returns {JSX.Element} Component template
 */
const PageView: FC<PageProps> = React.forwardRef<HTMLDivElement, PageProps>((props, ref) => {
  const [itemMedia] = useFetchMedia(props.media);

  useEffect(() => {
    let isSubscribed = true;

    if (props.index === 0 && itemMedia?.src) {
      getImageDimensions(`${MEDIA_PREFIX}${itemMedia?.src}`).then(object => {
        isSubscribed && props.setPageSize(object);
      }).catch(e => {});
    }

    return () => { isSubscribed = false };
    // eslint-disable-next-line
  }, [props.index, props.setPageSize, itemMedia?.src]);

  if (!itemMedia?.src) { return <div ref={ref}></div> }
  
  return (
    <div ref={ref} data-density="soft">
      <PageImg media={itemMedia} style={{width: `${props.bounds.width}px`, height: `${props.bounds.height}px`}}/>
    </div>
  );
})

export default FlipBookView;